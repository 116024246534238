import React, { useState, useEffect } from 'react';
import {
  Button, Select,
}
from 'antd';
import { setAnalytics } from '../../../utils';

const { Option } = Select;

const sortByOptions = {
  'Principal investigator': [
    {
      title: 'First Name',
      value: 'first_name',
      sortby: 'asc',
    },
    {
      title: 'Last Name',
      value: 'last_name',
      sortby: 'asc',
    },
    {
      title: '# Industry Studies',
      value: 'industry_studies',
      sortby: 'desc',
    },
    {
      title: '# Competing Studies',
      value: 'competing_studies',
      sortby: 'desc',
    },
    {
      title: 'PXL # Studies',
      value: 'pxl_studies',
      sortby: 'desc',
    },
    {
      title: 'Tier',
      value: 'tier',
      sortby: 'desc',
    },
    {
      title: 'Enrolled Subjects',
      value: 'enrolled_count',
      sortby: 'desc',
    },
    {
      title: 'Quality Rating',
      value: 'quality_rating',
      sortby: 'desc',
    },
    {
      title: 'Reliability',
      value: 'reliability_score',
      sortby: 'desc',
    },
    {
      title: 'KOL Score',
      value: 'cls_kol_score',
      sortby: 'desc',
    },
    {
      title: 'Site Name',
      value: 'site_name',
      sortby: 'asc',
    },
    {
      title: 'Country',
      value: 'country',
      sortby: 'asc',
    },
  ],
  'Primary organization': [
    {
      title: 'Site Name',
      value: 'site_name',
      sortby: 'asc',
    },
    {
      title: '# Industry Studies',
      value: 'industry_studies',
      sortby: 'desc',
    },
    {
      title: '# Competing Studies',
      value: 'competing_studies',
      sortby: 'desc',
    },
    {
      title: 'PXL # Studies',
      value: 'pxl_studies',
      sortby: 'desc',
    },
    {
      title: 'Tier',
      value: 'tier',
      sortby: 'desc',
    },
    {
      title: 'Enrolled Subjects',
      value: 'enrolled_count',
      sortby: 'desc',
    },
    {
      title: 'Quality Rating',
      value: 'quality_rating',
      sortby: 'desc',
    },
    {
      title: 'Reliability',
      value: 'reliability_score',
      sortby: 'desc',
    },
    {
      title: 'Country',
      value: 'country',
      sortby: 'asc',
    },
  ],
  'Parent institute': [
    {
      title: 'Parent Institute Name',
      value: 'parent_name',
      sortby: 'asc',
    },
    {
      title: '# Industry Studies',
      value: 'industry_studies',
      sortby: 'desc',
    },
    {
      title: '# Competing Studies',
      value: 'competing_studies',
      sortby: 'desc',
    },
    {
      title: 'PXL # Studies',
      value: 'pxl_studies',
      sortby: 'desc',
    },
    {
      title: 'Tier',
      value: 'tier',
      sortby: 'desc',
    },
    {
      title: 'Enrolled Subjects',
      value: 'enrolled_count',
      sortby: 'desc',
    },
    {
      title: 'Quality Rating',
      value: 'quality_rating',
      sortby: 'desc',
    },
    {
      title: 'Reliability',
      value: 'reliability_score',
      sortby: 'desc',
    },
    {
      title: 'Country',
      value: 'country',
      sortby: 'asc',
    },
  ],
};

const studiesSort = [
  {
    title: 'Start Date',
    value: 'date',
    sortby: 'asc',
  },
  {
    title: 'Sponsor Name',
    value: 'sponsor',
    sortby: 'desc',
  },
  {
    title: 'Official Title',
    value: 'official_title',
    sortby: 'desc',
  },
  {
    title: 'Indication',
    value: 'indication',
    sortby: 'desc',
  },
  {
    title: 'Intervention',
    value: 'interventions',
    sortby: 'desc',
  },
]
const recordTypeOptions = ['Parent institute', 'Primary organization', 'Principal investigator'];

const SortBy = (props) => {
  const [selectedRecordType, setSelectedRecordType] = useState('');
  const [selectedSort, setSelectedSort] = useState({});
  const [selectedSortOrder, setSelectedSortOrder] = useState('asc');

  useEffect(() => {
    if (props.topHierarchyItm) {
      setSelectedRecordType(props.topHierarchyItm);
      if (props.topHierarchyItm === 'Principal investigator') {
        setSelectedSort({
          title: '# Industry Studies',
          value: 'industry_studies',
          sortby: 'desc',
        })
        setSelectedSortOrder('desc');
      }
    }
  }, [props.topHierarchyItm])

  const onResetSort = () => {
    setSelectedSort({});
    setSelectedSortOrder('asc');
    props.onApplySort(undefined);
  }

  const onApplySort = () => {
    props.onApplySort({
      [selectedSort.value]: selectedSortOrder,
    })
    const param = { selectedSortOrder, selectedSort, selectedRecordType }
    setAnalytics('runAnalysisSort', 'data', param)
  }

  const onSortSelectionChange = (e) => {
    setSelectedSort(JSON.parse(e));
  }

  const onSortOrderChange = (e) => {
    setSelectedSortOrder(e);
  }

  return props.pageName === 'studies' ? (
    <>
      <div className="sortby-wrap sort-by-three-input">
        <div className="sortby-type-content">
          <div className="sortby-type-label">Sort by:</div>
          <Select popupClassName="sortby-single-select-dropdown" defaultValue={selectedSort.value ? JSON.stringify(selectedSort) : ''} value={selectedSort.value ? JSON.stringify(selectedSort) : ''} style={{ width: 200 }} onChange={onSortSelectionChange}>
            {studiesSort.map((sortField, index) => {
              return (
                <Option value={JSON.stringify(sortField)} key={index}>
                  {sortField.title}
                </Option>
              )
            })}
          </Select>
        </div>
        <div className="sortby-type-content sort-order-type-content">
          <div className="sortby-type-label">Sort order:</div>
          <Select popupClassName="sortby-single-select-dropdown sortby-single-select-dropdown-asc-dsc" style={{ width: 135 }} value={selectedSortOrder} onChange={onSortOrderChange}>
            <Option value="asc">
              <span className="sort-icon-img asc">Ascending</span>
            </Option>
            <Option value="desc">
              <span className="sort-icon-img desc">Descending</span>
            </Option>
          </Select>
        </div>
      </div>
      <div className="sortby-btns-wrap">
        <Button className="clear-btn" onClick={() => onResetSort()}>Reset</Button>
        <Button className="apply-btn" type="primary" onClick={() => onApplySort()}>Apply</Button>
      </div>
    </>
  ) : (
    <>
      <div className="sortby-wrap sort-by-three-input">
        <div className="sortby-type-content">
          <div className="sortby-type-label">Level:</div>
          <Select defaultValue={selectedRecordType} value={selectedRecordType} style={{ width: 200 }}>
            {recordTypeOptions.map((recordType, index) => (
              <Option value={recordType} key={index} disabled>
                {recordType}
              </Option>
            ))}
          </Select>
        </div>
        <div className="sortby-type-content">
          <div className="sortby-type-label">Sort by:</div>
          <Select popupClassName="sortby-single-select-dropdown" defaultValue={selectedSort.value ? JSON.stringify(selectedSort) : ''} value={selectedSort.value ? JSON.stringify(selectedSort) : ''} style={{ width: 200 }} onChange={onSortSelectionChange}>

            {selectedRecordType && sortByOptions[selectedRecordType].map((sortField, index) => {
              if (props.pageName === 'runAnalysis' && (sortField.value === 'tier' || sortField.value === 'reliability_score')) {
                return null;
              }
              return (
                <Option value={JSON.stringify(sortField)} key={index}>
                  {sortField.title}
                </Option>
              )
            })}
          </Select>
        </div>
        <div className="sortby-type-content sort-order-type-content">
          <div className="sortby-type-label">Sort order:</div>
          {
            // <div className="sortby-order-wrap">
            //   <div className="sortby-order-asc">Ascending</div>
            //   <div className="srotby-order-desc">Descending</div>
            // </div>
          }
          <Select popupClassName="sortby-single-select-dropdown sortby-single-select-dropdown-asc-dsc" style={{ width: 135 }} value={selectedSortOrder} onChange={onSortOrderChange}>
            <Option value="asc">
              <span className="sort-icon-img asc">Ascending</span>
            </Option>
            <Option value="desc">
              <span className="sort-icon-img desc">Descending</span>
            </Option>
          </Select>
        </div>
      </div>
      <div className="sortby-btns-wrap">
        <Button className="clear-btn" onClick={() => onResetSort()}>Reset</Button>
        <Button className="apply-btn" type="primary" onClick={() => onApplySort()}>Apply</Button>
      </div>
    </>
  )
};

export default SortBy;
